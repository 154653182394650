import { pipe } from "fp-ts/lib/function.js";
import { fromUnixTime } from "date-fns";
import { subMilliseconds } from "date-fns/fp";
import {
  isMasterRequestor,
  RequestorAuthTokenPayload,
  RequestorAuthTokenUserMetadata,
} from "@homewisedocs/common/lib/auth";
import {
  useGenericAuth,
  EAGER_EXPIRATION_WINDOW_MS,
} from "@homewisedocs/components/lib/Auth";

export type User = RequestorAuthTokenUserMetadata & {
  uuid: string;
  email: string;
  username: string;
  /**
   * If true, this user is the account's "master" user. In other words, they
   * are the user who created the account.
   */
  isMasterRequestor: boolean;
  /**
   * A [secured API key](https://www.algolia.com/doc/guides/security/api-keys/#secured-api-keys)
   * for sending queries to our Algolia HOA/address index.
   */
  algoliaHoaAddressApiKey: string;
};

const getUserFromTokenPayload = (
  decodedTokenPayload: RequestorAuthTokenPayload
): User => ({
  ...decodedTokenPayload.user_metadata,
  uuid: decodedTokenPayload.sub,
  username: decodedTokenPayload.username,
  email: decodedTokenPayload.email,
  isMasterRequestor: isMasterRequestor(decodedTokenPayload),
  algoliaHoaAddressApiKey: decodedTokenPayload.algolia_assoc_addr_api_key,
});

export type AuthProps =
  | {
      isAuthenticated: false;
    }
  | {
      isAuthenticated: true;
      /**
       * The time at which the client will consider the user's token to be expired.
       */
      effectiveTokenExpirationDate: Date;
      user: User;
    };

type UseAuthResult = Omit<ReturnType<typeof useGenericAuth>, "authProps"> & {
  authProps: AuthProps;
};

export const useAuth = (): UseAuthResult => {
  const authContext = useGenericAuth();
  const { authProps } = authContext;

  return {
    ...authContext,
    authProps: authProps.isAuthenticated
      ? {
          isAuthenticated: true,
          effectiveTokenExpirationDate: pipe(
            authProps.decodedAuthToken.exp,
            fromUnixTime,
            subMilliseconds(EAGER_EXPIRATION_WINDOW_MS)
          ),
          user: getUserFromTokenPayload(
            // The generic AuthProvider and its hook use the `GenericAuthTokenPayload`
            // type. But since this hook is specific to the requestor app, we
            // know the token should be for a requestor
            authProps.decodedAuthToken as RequestorAuthTokenPayload
          ),
        }
      : {
          isAuthenticated: false,
        },
  };
};
